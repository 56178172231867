import { ReactNode, createContext, useContext, useState } from 'react';
import { Product } from '../../types/product';
import { CalculateCartValues } from '../../utils/calculate-cart-values';

type CartContextType = {
    cartItems: Product[];
    addToCart: (item: Product) => void;
    removeFromCart: (item: Product) => void;
    cartCalculation: {
        orderPrice: number;
        shippingCharges: number;
        productDiscount: number;
        couponDiscount: number;
        total: number;
    },
    emptyCard: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

type CartProviderProps = {
    children: ReactNode;
}

const CartProvider = ({ children }: CartProviderProps) => {
    const [cartItems, setCartItems] = useState<Product[]>([]);

    // Load cart items from localStorage on component mount
    // useEffect(() => {
    //     const storedCart = localStorage.getItem('cart');
    //     if (storedCart) {
    //         setCartItems(JSON.parse(storedCart));
    //     }
    // }, []);

    // Save cart items to localStorage whenever they change
    // useEffect(() => {
    //     localStorage.setItem('cart', JSON.stringify(cartItems));
    // }, [cartItems]);

    const addToCart = (item: Product) => {
        const index = cartItems.length;
        const newItem = { ...item };
        newItem.id = index;
        newItem.orderItems = newItem.variants.filter((y) => y.type === newItem.orderedType);

        const data = [...cartItems];
        data.push(newItem);
        setCartItems(data);
    };


    const removeFromCart = (item: Product) => {
        setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
    };

    const emptyCard = () => {
        setCartItems([]);
    }

    const cartCalculation = CalculateCartValues(cartItems);

    const contextValue: CartContextType = {
        cartItems,
        cartCalculation,
        addToCart,
        removeFromCart,
        emptyCard
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

const useCart = (): CartContextType => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export { CartProvider, useCart };
