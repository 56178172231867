import { Product } from "../types/product";

export const CalculateCartValues = (cartItems: Product[]) => {

    let orderPrice = 0,
        shippingCharges = 0,
        productDiscount = 0,
        couponDiscount = 0,
        total = 0;

    cartItems.forEach(item => {
        orderPrice = orderPrice + (item?.orderItems[0].actualPrice * (item.orderQuantity || 0));
        productDiscount = productDiscount + (item.orderItems[0].discountPrice * (item.orderQuantity || 0));
    })

    if (cartItems.length) {
        shippingCharges = 50;
        couponDiscount = 100;

        total = orderPrice + shippingCharges - productDiscount - couponDiscount;
    }

    return {
        orderPrice,
        shippingCharges,
        productDiscount,
        couponDiscount,
        total
    }
}