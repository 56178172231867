import { Box, Card, CardContent, Container, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { config } from '../config';
import { Product } from '../types/product';

const ThankYouPage = () => {
    const location = useLocation();
    const { orderItems, orderResponse, total } = location.state || {};

    return (
        <Container>

            <Grid container spacing={3}>
                <Grid item xs={12} marginTop={5}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" align="center" gutterBottom>
                                Thank You for Your Order!
                            </Typography>
                            <Divider />
                            <Typography variant="body1" gutterBottom marginTop={3}>
                                Razorpay Payment ID: <span style={{ fontWeight: 800 }}>{orderResponse?.razorpay_payment_id}</span>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Razorpay Order ID: <span style={{ fontWeight: 800 }}>{orderResponse?.razorpay_order_id}</span>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Total: <span style={{ fontWeight: 800 }}>{total ?? 0}</span>
                            </Typography>
                            <Box marginTop={5}>
                                <TableContainer sx={{ marginTop: 3 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell sx={{ fontWeight: 600 }} align="right">Product</TableCell>
                                                <TableCell sx={{ fontWeight: 600 }} align="right">Size</TableCell>
                                                <TableCell sx={{ fontWeight: 600 }} align="right">Quantity</TableCell>
                                                <TableCell sx={{ fontWeight: 600 }} align="right">Price</TableCell>
                                                <TableCell sx={{ fontWeight: 600 }} align="right">Discount</TableCell>
                                                <TableCell sx={{ fontWeight: 600 }} align="right">Subtotal</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                orderItems?.map((item: Product, index: number) => (
                                                    <TableRow
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                        key={index}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <img src={config.serverImagePath + item.images[0].image} alt={item.images[0].alt} height={100} width={100} />
                                                        </TableCell>
                                                        <TableCell align="right">{item.name}</TableCell>
                                                        <TableCell align="right">{item.orderedType}</TableCell>
                                                        <TableCell align="right">{item.orderQuantity}</TableCell>
                                                        <TableCell align="right">₹ {item.orderItems[0]?.actualPrice}</TableCell>
                                                        <TableCell align="right">₹ {item.orderItems[0]?.discountPrice * (item.orderQuantity ?? 0)}</TableCell>
                                                        <TableCell align="right">₹ {item.orderItems[0]?.price * (item.orderQuantity ?? 0)}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box marginTop={4}>
                                    <Link to={"/"}>
                                        <Typography variant='body1' align='center'>
                                            Continue Shopping.
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ThankYouPage;
