import { CircularProgress } from '@mui/material';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { CartProvider } from './components/context/context';
import ThankYouPage from './pages/thankyou';

const Home = lazy(() => import('./pages/home'));
const Cart = lazy(() => import('./pages/cart'));
const Checkout = lazy(() => import('./pages/checkout'));
const ProductsByCategory = lazy(() => import('./pages/productsByCat'));
const ContactUs = lazy(() => import('./pages/contact'));
const MenuView = lazy(() => import('./pages/menuView'));

function App() {
  return (
    <CartProvider>
      <BrowserRouter basename="/">
        <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress color='warning' /></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/products/category/:catId" element={<ProductsByCategory />} />
            <Route path="/products/menu/:q" element={<MenuView />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/thankyou' element={<ThankYouPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;
